import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7e337e90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-center items-center p-2" }
const _hoisted_2 = { class: "text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", {
    class: _normalizeClass(["footer z-index place-items-center fixed bottom-0 h-7 border-t border-solid border-lightgrey bg-white", { 'w-40': _ctx.isSidebarToggle, 'w-10': !_ctx.isSidebarToggle }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, "© " + _toDisplayString(_ctx.year) + " Crystalline Software Technologies Pvt. Ltd", 1)
    ])
  ], 2))
}