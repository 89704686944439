<template>
    <div id="body-container">
        <navbar />
        <div class="flex" @click="closeApps()">
            <div :class="{ 'w-40': isTogglingData, 'w-10': !isTogglingData }">
                <sidemenubar />
            </div>
            <div class="content flex-1 overflow-y-scroll h-screen w-full" id="layoutcontent" :class="{ toggledstate: isTogglingData }">
                <div :class="flag ? 'margin-bottom1' : 'margin-bottom2'">
                    <slot />
                </div>
                <Footer />
            </div>
        </div>
    </div>
</template>
<style scoped>
/* .page{
    margin-bottom: 80px;
} */
.margin-bottom1 {
    margin-bottom: 80px;
}
.margin-bottom2 {
    margin-bottom: 80px;
}
</style>
<script lang="ts">
import navbar from '@/components/navbar.vue';
import sidemenubar from '@/components/sidemenubar.vue';
import Footer from '@/components/footer.vue';
import { defineComponent } from 'vue';
import { emitter } from '../main';
import { mapGetters } from 'vuex';
export default defineComponent({
    data(): any {
        return {
            isTogglingData: true,
            flag: true
        };
    },
    components: {
        navbar,
        sidemenubar,
        Footer
    },
    computed: {
        ...mapGetters({ userInfo: 'userInfo', toggleState: 'toggle' })
    },
    methods: {
        closeApps() {
            this.appsVisible = false;
            emitter.emit('apps', this.appsVisible);
        }
    },
    mounted() {
        this.isTogglingData = this.toggleState;
        emitter.on('toggle-sidebar', (isToggle) => {
            this.isTogglingData = isToggle;
        });
        if (this.$route.path.includes('/manageControlframeWorks')) {
            console.log('/manageControlframeWorks');
            this.flag = false;
        } else {
            this.flag = true;
        }
    }
});
</script>